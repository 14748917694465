import React, { useEffect, useState } from "react";
// import axios from 'axios';

import { useFormik } from "formik";
import * as Yup from "yup";

import { toast } from "react-toastify";
import { Axios, contentUrl } from "../../Services/Axios";
import ReactPaginate from "react-paginate";
import { Storage } from "../../Services/Storage";
import { fetchPermissions } from "../../Components/sidebar";

export const Centers = () => {
  const [formValues, setFormValues] = useState([]);
  const [cities, setCities] = React.useState([]);
  const [loading, setLoading] = useState(false);
  const [centerVideo, setCenterVideo] = React.useState(null);
  const [img, setImg] = React.useState(null);
  const [filtered, setFiltered] = React.useState([]);
  const [itemOffset, setItemOffset] = useState(0);
  const pageCount = Math.ceil(formValues.length / 10);
  const [permissions, setPermissions] = useState([]);
  const id = Storage.getItem("user").id;

  useEffect(() => {
    const endOffset = itemOffset + 10;
    console.log(`Loading items from ${itemOffset} to ${endOffset}`);
    setFiltered(formValues.slice(itemOffset, endOffset));
  }, [itemOffset, formValues]);

  const handlePage = (event) => {
    const newOffset = (event.selected * 10) % formValues.length;
    setItemOffset(newOffset);
  };

  useEffect(() => {
    fetchPermissions(id)
      .then((res) => {
        const permission = res.find((item) => item.title == "Centers");
        // console.log(permission);
        setPermissions(permission);
      })
      .catch((err) => {
        toast.error("Uncaught error");
      });
    GetAllCenters();
  }, []);

  const GetAllCenters = () => {
    setLoading(true);
    Axios.get("/Centres/GetAll").then(
      (res) => {
        setFormValues(res.data);
        GetAllCities();
      },
      () => {}
    );
  };
  const GetAllCities = () => {
    Axios.get("/City/GetAll").then(
      (res) => {
        setLoading(false);
        setCities(res.data);
      },
      () => {}
    );
  };

  const Delete = (id) => {
    Axios.delete(`/Centres/Remove?id=${id}`).then(
      () => {
        setFormValues((e) => [...e.filter((x) => x.id !== id)]);
        toast.success("Data Deleted Successfully...");
      },
      () => {
        toast.error("Something went wrong...");
      }
    );
  };

  const [open, setOpen] = useState(false);

  const edit = (e) => {
    formik.setValues(e);
    openModal();
  };

  const openModal = () => {
    if (open) {
      formik.resetForm();
    }
    setOpen(!open);
  };

  const validationSchema = Yup.object().shape({
    centreName: Yup.string().required("Center Name is required"),
    centreCode: Yup.string().required("Center Code is required"),
    phne: Yup.string().required("WhatsApp No. is required"),
    centreEmail: Yup.string()
      .email("Invalid email")
      .required("Email is required"),
    centreAddress: Yup.string().required("Address is required"),
    maapLink: Yup.string().required("Map URL is required"),
    phne2: Yup.string().required("Phone No. 1 is required"),
    phne3: Yup.string().required("Phone No. 2 is required"),
    cityId: Yup.string().required("City is required"),
    fbLink: Yup.string().required("Facebook Link is required"),
    twLink: Yup.string().required("Twitter Link is required"),
    inLink: Yup.string().required("LinkedIn Link is required"),
    ytLink: Yup.string().required("Youtube Link is required"),
  });
  const initialValues = {
    id: null,
    centreName: "",
    centreCode: "",
    phne: "",
    centreEmail: "",
    centreAddress: "",
    maapLink: "",
    phne2: "",
    phne3: "",
    cityId: "",
    fbLink: "",
    twLink: "",
    inLink: "",
    ytLink: "",
  };
  const handleSubmit = async (values, { resetForm }) => {
    const payload = new FormData();
    const keys = Object.keys(values);
    for (let i = 0; i < keys.length; i++) {
      const k = keys[i];
      if (values[k] && k !== "centreVideo" && k !== "image") {
        payload.append(k, values[k]);
      }
    }
    if (centerVideo) {
      payload.append("centreVideo", centerVideo);
    }
    if (img) {
      payload.append("image", img);
    }
    if (values.id) {
      Axios.put("/Centres/Update", payload, {
        headers: { "Content-Type": "multipart/form-data" },
      }).then(
        (res) => {
          setFormValues([
            ...formValues.filter((e) => e.id !== res.data.id),
            res.data,
          ]);
          resetForm();
          setOpen(!open);
          toast.success("Data inserted successfully");
        },
        (err) => {
          toast.error("Something went wrong...");
        }
      );
    } else {
      Axios.post("/Centres/Add", payload, {
        headers: { "Content-Type": "multipart/form-data" },
      }).then(
        (res) => {
          setFormValues([...formValues, res.data]);
          resetForm();
          setOpen(!open);
          toast.success("Data inserted successfully");
        },
        (err) => {
          toast.error("Something went wrong...");
        }
      );
    }
  };
  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: validationSchema,
    onSubmit: handleSubmit,
    enableReinitialize: true,
  });
  return (
    <>
      <div className="pageTitle mx-4 my-4">
        <div>
          <h3>Centers</h3>
        </div>
        {permissions.canAdd && (
          <button className="btn btntheme" onClick={openModal}>
            Add Center
          </button>
        )}
      </div>
      <div className="p-4">
        <div className="table border border-gray-200 rounded">
          <div className="flex tableheader !px-4">
            <div>Image</div>
            <div>Center Details</div>
            <div>Address</div>
            <div>Map URL</div>
            {permissions.canUpdate || permissions.canDelete ? (
              <div className="flex justify-center">Action</div>
            ) : null}
          </div>
          <div className="tablebody">
            {loading && <p className="p-4">Loading...</p>}
            {!loading && formValues.length === 0 && <p className="p-4">No data found.</p>}
            {!loading && formValues.length > 0 && (
              <>
                {filtered.map((e, index) => (
                  <div className="flex" key={index}>
                    <div>
                      <img
                        src={contentUrl + e.image}
                        alt="No Image Found..."
                        style={{
                          height: "100px",
                          width: "100px",
                          objectFit: "cover",
                          objectPosition: "center",
                        }}
                      />
                    </div>
                    <div>
                      <p>{e.centreName}</p>
                      <small>{e.centreCode}</small>
                      <p>{e.centreEmail}</p>
                      <p>{e.phne}</p>
                    </div>
                    <div>
                      <span className="my-des-alt">{e.centreAddress}</span>
                    </div>
                    <div>
                      <span className="my-des-alt">{e.maapLink}</span>
                    </div>
                    <div>
                      {permissions.canUpdate && (
                        <span className="mx-2" onClick={() => edit(e)}>
                          <i className="fa-solid fa-pen-to-square"></i>
                        </span>
                      )}
                      {permissions.canDelete && (
                        <span className="mx-2" onClick={() => Delete(e.id)}>
                          <i className="fa-solid fa-trash-can"></i>
                        </span>
                      )}
                    </div>
                  </div>
                ))}
                <ReactPaginate
                  breakLabel="..."
                  nextLabel="&raquo;"
                  onPageChange={handlePage}
                  pageRangeDisplayed={5}
                  pageCount={pageCount}
                  previousLabel="&laquo;"
                  renderOnZeroPageCount={null}
                  containerClassName="mars-pagination"
                  pageClassName="mars-page"
                  nextClassName="mars-page"
                  previousClassName="mars-page"
                  activeClassName="active"
                />
              </>
            )}
          </div>
        </div>
      </div>
      {open && (
        <div className="popup_overlay">
          <div className="popup_notify">
            <div className="popup_header">
              <h4>Add Center</h4>
              <button className="btn" onClick={openModal}>
                <i className="fa-solid fa-xmark"></i>
              </button>
            </div>
            <div className="popup_body">
              <form onSubmit={formik.handleSubmit}>
                <label htmlFor="centreName">Center Name</label>
                <input
                  type="text"
                  id="centreName"
                  className="input"
                  name="centreName"
                  placeholder="eg. Aptech Metro Star Gate"
                  value={formik.values?.centreName}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                />
                <small className="error">
                  {formik.touched.centreName && formik.errors.centreName}
                </small>
                <label htmlFor="centreCode">Center Code</label>
                <input
                  type="text"
                  id="centreCode"
                  className="input"
                  name="centreCode"
                  placeholder="eg. MSG"
                  value={formik.values?.centreCode}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                />
                <small className="error">
                  {formik.touched.centreCode && formik.errors.centreCode}
                </small>
                <label htmlFor="centreEmail">Email</label>
                <input
                  type="text"
                  id="centreEmail"
                  className="input"
                  name="centreEmail"
                  placeholder="eg. email@example.com"
                  value={formik.values?.centreEmail}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                />
                <small className="error">
                  {formik.touched.centreEmail && formik.errors.centreEmail}
                </small>
                <label htmlFor="phne2">
                  WhatsApp No. (visible on centre's location page)
                </label>
                <input
                  type="text"
                  id="phne2"
                  className="input"
                  name="phne2"
                  placeholder="eg. +9231126664496"
                  value={formik.values?.phne2}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                />
                <small className="error">
                  {formik.touched.phne2 && formik.errors.phne2}
                </small>

                <label htmlFor="centreAddress">Address</label>
                <input
                  type="text"
                  id="centreAddress"
                  className="input"
                  name="centreAddress"
                  placeholder="eg. Shah Faisal"
                  value={formik.values?.centreAddress}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                />
                <small className="error">
                  {formik.touched.centreAddress && formik.errors.centreAddress}
                </small>

                <label htmlFor="centreCity">City</label>
                <select
                  name="cityId"
                  id="cityId"
                  className="input"
                  value={formik.values?.cityId}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                >
                  <option value="" disabled>
                    Select Value
                  </option>
                  {cities.map((e) => (
                    <option value={e.id} key={e.id}>
                      {e.name}
                    </option>
                  ))}
                </select>
                <small className="error">
                  {formik.touched.cityId && formik.errors.cityId}
                </small>

                <label htmlFor="maapLink">Map URL</label>
                <input
                  type="text"
                  id="maapLink"
                  className="input"
                  name="maapLink"
                  placeholder="eg. "
                  value={formik.values?.maapLink}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                />
                <small className="error">
                  {formik.touched.maapLink && formik.errors.maapLink}
                </small>
                <label htmlFor="phne">
                  Phone No. 1 (visible on centre's location page)
                </label>
                <input
                  type="text"
                  id="phne"
                  className="input"
                  name="phne"
                  placeholder="eg. +9231126664496"
                  value={formik.values?.phne}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                />
                <small className="error">
                  {formik.touched.phne && formik.errors.phne}
                </small>
                <label htmlFor="phne">
                  Phone No. 2 (visible on centre's location page)
                </label>
                <input
                  type="text"
                  id="phne3"
                  className="input"
                  name="phne3"
                  placeholder="eg. +9231126664496"
                  value={formik.values?.phne3}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                />
                <small className="error">
                  {formik.touched.phne3 && formik.errors.phne3}
                </small>

                <label htmlFor="fbLink">Facebook URL</label>
                <input
                  type="text"
                  id="fbLink"
                  className="input"
                  name="fbLink"
                  placeholder="eg. "
                  value={formik.values?.fbLink}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                />
                <small className="error">
                  {formik.touched.fbLink && formik.errors.fbLink}
                </small>
                <label htmlFor="twLink">Twitter URL</label>
                <input
                  type="text"
                  id="twLink"
                  className="input"
                  name="twLink"
                  placeholder="eg. "
                  value={formik.values?.twLink}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                />
                <small className="error">
                  {formik.touched.twLink && formik.errors.twLink}
                </small>
                <label htmlFor="inLink">LinkedIn URL</label>
                <input
                  type="text"
                  id="inLink"
                  className="input"
                  name="inLink"
                  placeholder="eg. "
                  value={formik.values?.inLink}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                />
                <small className="error">
                  {formik.touched.inLink && formik.errors.inLink}
                </small>
                <label htmlFor="ytLink">Youtube URL</label>
                <input
                  type="text"
                  id="ytLink"
                  name="ytLink"
                  className="input"
                  placeholder="eg. "
                  value={formik.values?.ytLink}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                />
                <small className="error">
                  {formik.touched.ytLink && formik.errors.ytLink}
                </small>
                <label htmlFor="CentreVideo">Center Video</label>
                <input
                  type="file"
                  id="CentreVideo"
                  className="input"
                  name="CentreVideo"
                  accept="video/*"
                  onChange={(e) => {
                    setCenterVideo(e.target.files[0]);
                  }}
                />
                <label htmlFor="CentreVideo">Center Image</label>
                <input
                  type="file"
                  id="image"
                  className="input"
                  name="image"
                  accept="image/*"
                  onChange={(e) => {
                    setImg(e.target.files[0]);
                  }}
                />

                <div className="popup_footer">
                  <button className="btn btnclose" onClick={openModal}>
                    Close
                  </button>
                  <button type="submit" className="btn btntheme">
                    Save
                  </button>
                </div>
              </form>
            </div>
            {/* <div className="popup_footer">
                <button className="btn btnclose"  onClick={openModal}>Close</button>
                <button className="btn btntheme">Save</button>
            </div> */}
          </div>
        </div>
      )}
    </>
  );
};
