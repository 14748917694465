import { Field, Formik, Form, ErrorMessage } from "formik";
import React from "react";
import * as yup from "yup";
import { decryptData, getItem, Storage } from "../../Services/Storage";
import { Axios } from "../../Services/Axios";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";
import { jwtDecode } from "jwt-decode";
import { Fragment, useState } from "react";
import { Dialog, Listbox, Transition } from "@headlessui/react";
import { CheckIcon, ChevronUpDownIcon } from "@heroicons/react/20/solid";
import {
  adminModules,
  fetchPermissions,
  userModules,
} from "../../Components/sidebar";
import Modal from "../../Components/Modal";
import DeleteModal from "../../Components/DeleteModal";
import ManagePermissions from "../../Components/ManagePermissions";
// import EditUser from "../../Components/editUser";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

let tabs = [
  { name: "Change Password", ref: "changePassword", current: true },
  { name: "Manage Center", ref: "permissions", current: false },

  {
    name: "Add Users",
    ref: "addUsers",
    current: false,
  },
];

const Index = () => {
  const [userId, setUserId] = React.useState();
  const [loading, setloading] = React.useState(false);
  const [showOldPassword, setShowOldPassword] = React.useState(false);
  const [showPassword, setShowPassword] = React.useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = React.useState(false);
  const [screen, setScreen] = React.useState("changePassword");
  const [centers, setCenters] = React.useState([]);
  const [centerUsers, setCenterUsers] = React.useState([]);
  const [open, setOpen] = React.useState(false);
  const [editModal, setEditModal] = React.useState(false);
  const [addAdminModal, setAddAdminModal] = React.useState(false);
  const [manageAdminPermissions, setManageAdminPermissions] =
    React.useState(false);
  const [editCenterModal, setEditCenterModal] = React.useState(false);
  const [permissionsLoading, setPermissionsLoading] = React.useState(false);
  const [deleteAdminModal, setDeleteAdminModal] = React.useState(false);
  const [deleteLoading, setDeleteLoading] = React.useState(false);
  const [deleteRecord, setDeleteRecord] = React.useState(false);
  const [addUserModal, setaddUserModal] = useState(false);
  const [deleteRecordType, setDeleteRecordType] = React.useState("");
  const [centerId, setCenterId] = React.useState("");
  const [editModalData, setEditModalData] = React.useState([]);
  const [initVal, setInitVal] = React.useState({});
  const [userPermissions, setUserPermissions] = React.useState([]);
  const [modalType, setModalType] = React.useState("");
  const [permissions, setPermissions] = useState([]);
  const [superAdmins, setSuperAdmins] = useState([]);
  const [allRoles, setAllRoles] = useState([]);
  const [centerUserId, setCenterUserId] = useState("");
  const [employeeRoleId, setEmployeeRoleId] = useState("");

  const cancelButtonRef = React.useRef(null);
  const { role, centresId, id } = Storage?.getItem("user");
  const user = Storage.getItem("user");
  // console.log(users);
  const addUserInitVal =
    role === "center" || role === "employee"
      ? {
          name: "",
          username: "",
          password: "",
          confirmPassword: "",
          centresId: centresId,
          email: "",
          roleId: employeeRoleId,
        }
      : {
          name: "",
          username: "",
          password: "",
          confirmPassword: "",
          centresId: centerId,
          email: "",
          roleId: employeeRoleId,
        };

  const getCenterUserId = (email) => {
    return Axios.get(`/Users/GetByEmail?Email=${email}`)
      .then((res) => {
        setCenterUserId(res.data.id);
      })
      .catch((err) => toast.error("Unable to edit center"));
  };

  const toggleShowOldPassword = () => {
    setShowOldPassword(!showOldPassword);
  };

  const toggleShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const toggleShowConfirmPassword = () => {
    setShowConfirmPassword(!showConfirmPassword);
  };

  const getAllRoles = () => {
    Axios.get("/Role/GetAll")
      .then((res) => {
        const empRole = res.data.find((role) => role.roleName == "employee");
        setEmployeeRoleId(empRole.id);
        setAllRoles(res.data);
      })
      .catch((err) => {
        console.log(err)
        toast.error("Uncaught Error")});
  };

  const GetAllCenters = () => {
    // setLoading(true);
    Axios.get("/Centres/GetAll").then(
      (res) => {
        setCenters(res.data);
      },
      () => {}
    );
  };

  const fetchUserPermissions = (id, person) => {
    setPermissionsLoading(true);
    Axios.get(`/Permissions/GetByUserId?Id=${id}`)
      .then((res) => {
        if (res.data.length > 0) {
          setModalType("update");
          setUserPermissions(res.data);
          const permissions = {};
          res.data.forEach((permission) => {
            Object.keys(permission).forEach((key) => {
              if (key.startsWith("can")) {
                // console.log(permission, key)
                permissions[key.substring(3).toLowerCase() + permission.title] =
                  permission[key];
              }
            });
          });

          if (person == "admin") {
            adminModules.forEach(({ name }) => {
              let view = permissions[`view${name}`];
              let add = permissions[`add${name}`];
              let update = permissions[`update${name}`];
              let del = permissions[`delete${name}`];

              // Check if any of the permissions are true for the current category
              if (view || add || update || del) {
                permissions[name] = true;
              }
            });

            setInitVal(permissions);
          } else {
            userModules.forEach(({ name }) => {
              let view = permissions[`view${name}`];
              let add = permissions[`add${name}`];
              let update = permissions[`update${name}`];
              let del = permissions[`delete${name}`];

              // Check if any of the permissions are true for the current category
              if (view || add || update || del) {
                permissions[name] = true;
              }
            });

            setInitVal(permissions);
          }
          // setUserPermissions(res.data);
        } else {
          setModalType("add");
          if (person == "admin") {
            initialValues(adminModules); // Pass res.data to initialValues
          } else {
            initialValues(userModules); // Pass res.data to initialValues
          }
        }

        setPermissionsLoading(false);
      })
      .catch((err) => console.log(err));
  };

  const fetchCenterUserId = (id) => {
    return Axios.get(`/Users/GetByCampusAndRole?Id=${id}&Role=center`)
      .then((res) => {
        setUserId(res.data[0].id);
        return res.data[0].id;
      })
      .catch((err) => {
        console.log(err);
        toast.error("Uncaught error");
      });
  };

  const fetchCentereUsers = (id) => {
    return Axios.get(
      `/Users/GetByCampusAndRole?Id=${centresId ? centresId : id}&Role=employee`
    )
      .then((res) => {
        setCenterUsers(res.data);
      })
      .catch((err) => {
        console.log(err);
        throw err; // Re-throw the error to handle it later
      });
  };

  const fetchSuperAdmins = () => {
    return Axios.get("/Users/GetByCampusAndRole?Role=admin")
      .then((res) => {
        setSuperAdmins(res.data);
      })
      .catch((err) => toast.error("Error Fetching Admins"));
  };

  const deleteUser = (id) => {
    Axios.delete("/User/De");
  };
  const initialValues = (modules) => {
    // console.log(userPermissions)
    const val = {};
    modules.forEach(({ name }) => {
      val[name] = false;
      val[`view${name}`] = false;
      val[`add${name}`] = false;
      val[`update${name}`] = false;
      val[`delete${name}`] = false;
    });

    setInitVal(val);
  };

  React.useEffect(() => {
    tabs.forEach((element) => {
      if (element.ref == "changePassword") {
        element.current = true;
      } else {
        element.current = false;
      }
    });
    // initialValues();
    fetchPermissions(id)
      .then((res) => {
        const selectedPermission = res.find((item) => item.title == "Settings");
        // console.log(selectedPermission);
        setPermissions(selectedPermission);

        if (!selectedPermission.canAdd) {
          const filteredTabs = tabs.filter(
            (item) => item.ref !== "addAdmin" && item.ref !== "addUsers"
          );

          tabs = filteredTabs;
        }
        GetAllCenters();
      })
      .catch((err) => {
        console.log(err);
        toast.error("Uncaught error");
      });

    if (role == "admin") {
      const tab = tabs.find((tab) => tab.ref == "addAdmin");

      if (!tab) {
        tabs.push({
          name: "FSO",
          ref: "addAdmin",
          current: false,
        });
      }
      fetchSuperAdmins();
    }

    if (role == "center" || role == "employee") {
      const filteredTabs = tabs.filter((item) => item.ref !== "addUsers");

      tabs = filteredTabs;
      fetchCentereUsers();
    }
    getAllRoles();

    setUserId(id)

  }, []);

  return (
    <>
      <div className="flex justify-center my-4">
        <div className="sm:hidden">
          <label htmlFor="tabs" className="sr-only">
            Select a tab
          </label>
          {/* Use an "onChange" listener to redirect the user to the selected tab URL. */}
          <select
            id="tabs"
            name="tabs"
            className="block w-full rounded-md border-gray-300 focus:border-yellow-500 focus:ring-yellow-500"
            defaultValue={tabs.find((tab) => tab.current).name}
          >
            {tabs.map((tab) => (
              <option key={tab.name}>{tab.name}</option>
            ))}
          </select>
        </div>
        <div className=" sm:block">
          <nav
            className="isolate divide-x divide-gray-200 rounded-lg shadow"
            aria-label="Tabs"
          >
            {tabs.map((tab, tabIdx) => (
              <button
                key={tab.name}
                onClick={() => {
                  setScreen(tab.ref);

                  tabs.forEach((element) => {
                    if (element.ref !== tab.ref) {
                      element.current = false;
                    } else {
                      element.current = true;
                    }
                  });
                }}
                className={classNames(
                  tab.current
                    ? "text-gray-900"
                    : "text-gray-500 hover:text-gray-700",
                  tabIdx === 0 ? "rounded-l-lg" : "",
                  tabIdx === tabs.length - 1 ? "rounded-r-lg" : "",
                  "group relative min-w-0 flex-1 overflow-hidden bg-white py-4 px-4 text-sm font-medium text-center hover:bg-gray-50 focus:z-10"
                )}
                aria-current={tab.current ? "page" : undefined}
              >
                <span>{tab.name}</span>
                <span
                  aria-hidden="true"
                  className={classNames(
                    tab.current ? "bg-yellow-300" : "bg-transparent",
                    "absolute inset-x-0 bottom-0 h-0.5"
                  )}
                />
              </button>
            ))}
          </nav>
        </div>
      </div>

      {screen === "changePassword" && (
        <div className="w-full max-w-md mx-auto mt-10 p-6 border rounded-lg shadow-lg bg-white">
          <h2 className="text-2xl font-bold mb-4">Change Password</h2>
          <Formik
            initialValues={{
              oldPassword: "",
              password: "",
              confirmPassword: "",
            }}
            validationSchema={yup.object().shape({
              oldPassword: yup.string().required("Required"),
              password: yup.string().required("New password is required"),
              confirmPassword: yup
                .string()
                .oneOf([yup.ref("password"), null], "Passwords must match")
                .required("Please confirm your password"),
            })}
            onSubmit={(val, { resetForm, setFieldError }) => {
              setloading(true);
              const { oldPassword, password } = val;
              Axios.post("/Users/ChangePassword", {
                userId,
                oldPassword,
                password,
              })
                .then((res) => {
                  setloading(false);
                  // console.log(res)
                  toast.success(res.data);
                  resetForm();
                })
                .catch((err) => {
                  setloading(false);
                  if (err?.response?.status) {
                    setFieldError("confirmPassword", err?.response?.data);
                  }
                });
            }}
          >
            <Form className="space-y-4">
              <div className="space-y-2">
                <label
                  htmlFor="oldPassword"
                  className="block text-sm font-medium text-gray-700"
                >
                  Old Password
                </label>
                <div className="relative">
                  <Field
                    type={showOldPassword ? "text" : "password"}
                    id="oldPassword"
                    name="oldPassword"
                    className="block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-yellow-500 focus:border-yellow-500 sm:text-sm"
                    required
                  />
                  <span
                    className="absolute inset-y-0 right-0 flex items-center pr-3 cursor-pointer"
                    onClick={toggleShowOldPassword}
                  >
                    {showOldPassword ? (
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                      >
                        <path d="M11.885 14.988l3.104-3.098.011.11c0 1.654-1.346 3-3 3l-.115-.012zm8.048-8.032l-3.274 3.268c.212.554.341 1.149.341 1.776 0 2.757-2.243 5-5 5-.631 0-1.229-.13-1.785-.344l-2.377 2.372c1.276.588 2.671.972 4.177.972 7.733 0 11.985-8.449 11.985-8.449s-1.415-2.478-4.067-4.595zm1.431-3.536l-18.619 18.58-1.382-1.422 3.455-3.447c-3.022-2.45-4.818-5.58-4.818-5.58s4.446-7.551 12.015-7.551c1.825 0 3.456.426 4.886 1.075l3.081-3.075 1.382 1.42zm-13.751 10.922l1.519-1.515c-.077-.264-.132-.538-.132-.827 0-1.654 1.346-3 3-3 .291 0 .567.055.833.134l1.518-1.515c-.704-.382-1.496-.619-2.351-.619-2.757 0-5 2.243-5 5 0 .852.235 1.641.613 2.342z" />
                      </svg>
                    ) : (
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                      >
                        <path d="M15 12c0 1.654-1.346 3-3 3s-3-1.346-3-3 1.346-3 3-3 3 1.346 3 3zm9-.449s-4.252 8.449-11.985 8.449c-7.18 0-12.015-8.449-12.015-8.449s4.446-7.551 12.015-7.551c7.694 0 11.985 7.551 11.985 7.551zm-7 .449c0-2.757-2.243-5-5-5s-5 2.243-5 5 2.243 5 5 5 5-2.243 5-5z" />
                      </svg>
                    )}
                  </span>
                </div>
                <ErrorMessage
                  name="oldPassword"
                  component="div"
                  className="text-red-500 text-sm"
                />
              </div>

              <div className="space-y-2">
                <label
                  htmlFor="password"
                  className="block text-sm font-medium text-gray-700"
                >
                  New Password
                </label>
                <div className="relative">
                  <Field
                    type={showPassword ? "text" : "password"}
                    id="password"
                    name="password"
                    className="block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-yellow-500 focus:border-yellow-500 sm:text-sm"
                    required
                  />
                  <span
                    className="absolute inset-y-0 right-0 flex items-center pr-3 cursor-pointer"
                    onClick={toggleShowPassword}
                  >
                    {showPassword ? (
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                      >
                        <path d="M11.885 14.988l3.104-3.098.011.11c0 1.654-1.346 3-3 3l-.115-.012zm8.048-8.032l-3.274 3.268c.212.554.341 1.149.341 1.776 0 2.757-2.243 5-5 5-.631 0-1.229-.13-1.785-.344l-2.377 2.372c1.276.588 2.671.972 4.177.972 7.733 0 11.985-8.449 11.985-8.449s-1.415-2.478-4.067-4.595zm1.431-3.536l-18.619 18.58-1.382-1.422 3.455-3.447c-3.022-2.45-4.818-5.58-4.818-5.58s4.446-7.551 12.015-7.551c1.825 0 3.456.426 4.886 1.075l3.081-3.075 1.382 1.42zm-13.751 10.922l1.519-1.515c-.077-.264-.132-.538-.132-.827 0-1.654 1.346-3 3-3 .291 0 .567.055.833.134l1.518-1.515c-.704-.382-1.496-.619-2.351-.619-2.757 0-5 2.243-5 5 0 .852.235 1.641.613 2.342z" />
                      </svg>
                    ) : (
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                      >
                        <path d="M15 12c0 1.654-1.346 3-3 3s-3-1.346-3-3 1.346-3 3-3 3 1.346 3 3zm9-.449s-4.252 8.449-11.985 8.449c-7.18 0-12.015-8.449-12.015-8.449s4.446-7.551 12.015-7.551c7.694 0 11.985 7.551 11.985 7.551zm-7 .449c0-2.757-2.243-5-5-5s-5 2.243-5 5 2.243 5 5 5 5-2.243 5-5z" />
                      </svg>
                    )}
                  </span>
                </div>
                <ErrorMessage
                  name="password"
                  component="div"
                  className="text-red-500 text-sm"
                />
              </div>

              <div className="space-y-2">
                <label
                  htmlFor="confirmPassword"
                  className="block text-sm font-medium text-gray-700"
                >
                  Confirm Password
                </label>
                <div className="relative">
                  <Field
                    type={showConfirmPassword ? "text" : "password"}
                    id="confirmPassword"
                    name="confirmPassword"
                    className="block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-yellow-500 focus:border-yellow-500 sm:text-sm"
                    required
                  />
                  <span
                    className="absolute inset-y-0 right-0 flex items-center pr-3 cursor-pointer"
                    onClick={toggleShowConfirmPassword}
                  >
                    {showConfirmPassword ? (
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                      >
                        <path d="M11.885 14.988l3.104-3.098.011.11c0 1.654-1.346 3-3 3l-.115-.012zm8.048-8.032l-3.274 3.268c.212.554.341 1.149.341 1.776 0 2.757-2.243 5-5 5-.631 0-1.229-.13-1.785-.344l-2.377 2.372c1.276.588 2.671.972 4.177.972 7.733 0 11.985-8.449 11.985-8.449s-1.415-2.478-4.067-4.595zm1.431-3.536l-18.619 18.58-1.382-1.422 3.455-3.447c-3.022-2.45-4.818-5.58-4.818-5.58s4.446-7.551 12.015-7.551c1.825 0 3.456.426 4.886 1.075l3.081-3.075 1.382 1.42zm-13.751 10.922l1.519-1.515c-.077-.264-.132-.538-.132-.827 0-1.654 1.346-3 3-3 .291 0 .567.055.833.134l1.518-1.515c-.704-.382-1.496-.619-2.351-.619-2.757 0-5 2.243-5 5 0 .852.235 1.641.613 2.342z" />
                      </svg>
                    ) : (
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                      >
                        <path d="M15 12c0 1.654-1.346 3-3 3s-3-1.346-3-3 1.346-3 3-3 3 1.346 3 3zm9-.449s-4.252 8.449-11.985 8.449c-7.18 0-12.015-8.449-12.015-8.449s4.446-7.551 12.015-7.551c7.694 0 11.985 7.551 11.985 7.551zm-7 .449c0-2.757-2.243-5-5-5s-5 2.243-5 5 2.243 5 5 5 5-2.243 5-5z" />
                      </svg>
                    )}
                  </span>
                </div>
                <ErrorMessage
                  name="confirmPassword"
                  component="div"
                  className="text-red-500 text-sm"
                />
              
              </div>

              <button
                type="submit"
                className={`w-full py-2 px-4 rounded-md shadow-sm text-white ${
                  loading
                    ? "bg-yellow-400 cursor-not-allowed"
                    : "bg-yellow-500 hover:bg-yellow-600"
                } focus:outline-none focus:ring-2 focus:ring-yellow-500 focus:ring-offset-2`}
                disabled={loading}
              >
                {loading ? "Updating..." : "Submit"}
              </button>
            </Form>
          </Formik>
        </div>
      )}

      {screen == "permissions" && (
        <div className="px-4 sm:px-6 lg:px-8">
          {role == "admin" ? (
            <div className="-mx-4 mt-8 overflow-hidden shadow ring-1 ring-black ring-opacity-5 sm:-mx-6 md:mx-0 md:rounded-lg">
              <table className="min-w-full divide-y divide-gray-300">
                <thead className="bg-gray-50">
                  <tr>
                    <th
                      scope="col"
                      className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6"
                    >
                      Center name
                    </th>

                    <th
                      scope="col"
                      className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6"
                    ></th>
                    <th
                      scope="col"
                      className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6"
                    ></th>

                    {permissions?.canUpdate || permissions?.canDelete ? (
                      <th
                        scope="col"
                        className="px-7 py-3.5 text-right text-sm font-semibold text-gray-900 lg:table-cell"
                      >
                        Actions
                      </th>
                    ) : null}
                  </tr>
                </thead>
                <tbody className="divide-y divide-gray-200 bg-white">
                  {centers.length <= 0 && (
                    <tr>
                      <td className="text-base p-4 text-red-600">
                        No Users Found
                      </td>
                    </tr>
                  )}
                  {centers.map((center, i) => (
                    <tr key={i}>
                      <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
                        {center.centreName}
                      </td>
                      <td
                        className=" whitespace-nowrap px-3 py-4 text-sm text-blue-500 sm:table-cell cursor-pointer"
                        onClick={() => {
                          setScreen("EmployeeTable");
                          setCenterId(center.id);
                          // setOpen(!open);
                          Axios.get(
                            `/Users/GetByCampusAndRole?Id=${center.id}&Role=employee`
                          )
                            .then((res) => {
                              setCenterUsers(res.data);
                            })
                            .catch((err) => console.log(err));
                        }}
                      >
                        Manage Users
                      </td>
                      {permissions?.canUpdate && (
                        <td
                          className=" whitespace-nowrap px-3 py-4 text-sm text-blue-500 sm:table-cell cursor-pointer"
                          onClick={() => {
                            setOpen(true);

                            console.log(center);
                            fetchCenterUserId(center.id)
                              .then((res) => {
                                fetchUserPermissions(res, "center");
                              })
                              .catch((err) => {
                                toast.error("Error");
                                console.log(err);
                              });
                          }}
                        >
                          Manage Permissions
                        </td>
                      )}
                      {permissions?.canUpdate || permissions?.canDelete ? (
                        <td className="text-right px-4">
                          {permissions?.canUpdate && (
                            <span
                              className="mx-2"
                              onClick={() => {
                                getCenterUserId(center.centreEmail).then(
                                  (res) => {
                                    setEditCenterModal(true);
                                    setEditModalData({
                                      name: center.centreName,
                                      email: center.centreEmail,
                                      userId: center.id,
                                    });
                                  }
                                );
                                // setScreen("EmployeeTable");
                                // // setOpen(!open);
                                // Axios.get(
                                //   `/Users/GetByCampusAndRole?Id=${center.id}&Role=employee`
                                // )
                                //   .then((res) => {
                                //     setCenterUsers(res.data);
                                //   })
                                //   .catch((err) => console.log(err));
                              }}
                            >
                              <i className="fa-solid fa-pen-to-square"></i>
                            </span>
                          )}
                          {permissions?.canDelete && (
                            <span
                              className="mx-2"
                              // onClick={() => Delete(e.id)}
                            >
                              <i className="fa-solid fa-trash-can"></i>
                            </span>
                          )}
                        </td>
                      ) : null}
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          ) : role == "center" || role == "employee" ? (
            <>
              {permissions.canAdd && (
                <div className="flex justify-end mx-4">
                  <button
                    className="btn btntheme"
                    onClick={() => setaddUserModal(!addUserModal)}
                  >
                    Add user
                  </button>
                </div>
              )}
              <div className="-mx-4 mt-2 overflow-hidden shadow ring-1 ring-black ring-opacity-5 sm:-mx-6 md:mx-0 md:rounded-lg">
                <table className="min-w-full divide-y divide-gray-300">
                  <thead className="bg-gray-50">
                    <tr>
                      <th
                        scope="col"
                        className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6"
                      >
                        Users
                      </th>
                      {permissions.canUpdate && (
                        <th
                          scope="col"
                          className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6"
                        >
                          Permissions
                        </th>
                      )}
                      {permissions?.canUpdate || permissions?.canDelete ? (
                        <th
                          scope="col"
                          className="px-7 py-3.5 text-right text-sm font-semibold text-gray-900 lg:table-cell"
                        >
                          Actions
                        </th>
                      ) : null}
                    </tr>
                  </thead>
                  <tbody className="divide-y divide-gray-200 bg-white">
                    {centerUsers.length <= 0 && (
                      <tr>
                        <td className="text-base p-4 text-red-600 ">
                          No users found
                        </td>
                      </tr>
                    )}
                    {centerUsers.map((user, i) => (
                      <tr key={i}>
                        <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
                          {user.username}
                        </td>

                        {permissions.canUpdate && (
                          <td
                            className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium sm:pl-6 text-blue-600 cursor-pointer"
                            onClick={() => {
                              setOpen(true);
                              fetchUserPermissions(user.id);
                              setUserId(user.id);
                            }}
                          >
                            Manage permissions
                          </td>
                        )}

                        {permissions.canUpdate || permissions.canDelete ? (
                          <td className="text-right px-4">
                            {permissions.canUpdate && (
                              <span
                                className="mx-2 "
                                onClick={() => {
                                  setEditModal(true);
                                  setEditModalData(user);
                                }}
                              >
                                <i className="fa-solid fa-pen-to-square"></i>
                              </span>
                            )}
                            {permissions.canDelete && (
                              <span
                                className="mx-2"
                                onClick={() => {
                                  setDeleteRecordType("user");
                                  setDeleteRecord(user);
                                  setDeleteAdminModal(true);
                                }}
                              >
                                <i className="fa-solid fa-trash-can"></i>
                              </span>
                            )}
                          </td>
                        ) : null}
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </>
          ) : null}
        </div>
      )}
      {screen == "addUsers" && permissions?.canAdd && (
        <div className="flex min-h-full flex-col justify-center  sm:px-6 lg:px-8">
          <div className="mt-8 sm:mx-auto sm:w-full sm:max-w-lg">
            <div className=""></div>
            <div className="bg-white py-8 px-4 shadow-md sm:rounded-lg sm:px-10">
              <Formik
                initialValues={addUserInitVal}
                validationSchema={yup.object().shape({
                  username: yup
                    .string()
                    .min(3, "Username must be at least 3 characters")
                    .max(20, "Username can't exceed 20 characters")
                    .required("Username is required"),

                  email: yup
                    .string()
                    .matches(
                      /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
                      "Invalid email format"
                    )
                    .required("Email is required"),

                  password: yup
                    .string()
                    .min(8, "Password must be at least 8 characters")
                    .matches(
                      /[A-Z]/,
                      "Password must contain an uppercase letter"
                    )
                    .matches(
                      /[a-z]/,
                      "Password must contain a lowercase letter"
                    )
                    .matches(/[0-9]/, "Password must contain a number")
                    .matches(
                      /[@$!%*?&#]/,
                      "Password must contain a special character"
                    )
                    .required("Password is required"),

                  confirmPassword: yup
                    .string()
                    .oneOf([yup.ref("password"), null], "Passwords must match")
                    .required("Please confirm your password"),
                })}
                onSubmit={(
                  values,
                  { resetForm, setFieldError, setSubmitting }
                ) => {
                  Axios.post("/Users/Register", values)
                    .then((res) => {
                      toast.success("User created successfully");
                      resetForm();
                      setSubmitting(false);
                    })
                    .catch((err) => {
                      setFieldError("username", err.response.data);
                      setSubmitting(false);
                    });
                }}
                className="space-y-6"
              >
                {(formik) => (
                  <form onSubmit={formik.handleSubmit} className="space-y-6">
                    <h3 className="font-semibold font-sans text-2xl text-center">
                      Add User
                    </h3>
                    <p className=" text-gray-500 font-sans text-base">
                      Here, you can easily add new users to the system by
                      filling out the form below. Please provide all the
                      required information to ensure a smooth onboarding
                      process.
                    </p>

                    <div>
                      <label
                        htmlFor="username"
                        className="block text-sm font-medium text-gray-700"
                      >
                        Username
                      </label>
                      <div className="mt-1">
                        <Field
                          id="username"
                          name="username"
                          type="text"
                          className="block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm focus:border-yellow-500 focus:outline-none focus:ring-yellow-500 sm:text-sm"
                        />
                        <ErrorMessage
                          name="username"
                          component="div"
                          className="text-red-500 text-sm"
                        />
                      </div>
                    </div>
                    {role == "admin" ? (
                      <Listbox
                        value={formik.values.centresId}
                        onChange={(value) =>
                          formik.setFieldValue("centresId", value)
                        }
                      >
                        {({ open }) => (
                          <>
                            <div className="relative mt-1">
                              <Listbox.Button className="relative w-full cursor-default rounded-md border border-gray-300 bg-white py-2 pl-3 pr-10 text-left shadow-sm focus:border-yellow-500 focus:outline-none focus:ring-1 focus:ring-yellow-500 sm:text-sm">
                                <span className="block truncate">
                                  {formik.values.centresId
                                    ? centers.find(
                                        (c) => c.id === formik.values.centresId
                                      ).centreName
                                    : "Select center"}
                                </span>
                                <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                                  <ChevronUpDownIcon
                                    className="h-5 w-5 text-gray-400"
                                    aria-hidden="true"
                                  />
                                </span>
                              </Listbox.Button>

                              <Transition
                                show={open}
                                as={Fragment}
                                leave="transition ease-in duration-100"
                                leaveFrom="opacity-100"
                                leaveTo="opacity-0"
                              >
                                <Listbox.Options className="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                                  {centers.map((person) => (
                                    <Listbox.Option
                                      key={person.id}
                                      value={person.id}
                                      className={({ active }) =>
                                        classNames(
                                          active
                                            ? "text-white bg-yellow-300"
                                            : "text-gray-900",
                                          "relative cursor-default select-none py-2 pl-3 pr-9"
                                        )
                                      }
                                    >
                                      {({ selected, active }) => (
                                        <>
                                          <span
                                            className={classNames(
                                              selected
                                                ? "font-semibold"
                                                : "font-normal",
                                              "block truncate"
                                            )}
                                          >
                                            {person.centreName}
                                          </span>
                                          {selected ? (
                                            <span
                                              className={classNames(
                                                active
                                                  ? "text-white"
                                                  : "text-yellow-300",
                                                "absolute inset-y-0 right-0 flex items-center pr-4"
                                              )}
                                            >
                                              <CheckIcon
                                                className="h-5 w-5"
                                                aria-hidden="true"
                                              />
                                            </span>
                                          ) : null}
                                        </>
                                      )}
                                    </Listbox.Option>
                                  ))}
                                </Listbox.Options>
                              </Transition>
                            </div>
                          </>
                        )}
                      </Listbox>
                    ) : (
                      <input
                        type="text"
                        className="block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm focus:border-yellow-500 focus:outline-none focus:ring-yellow-500 sm:text-sm"
                        value={
                          centers.find((c) => c.id === formik.values.centresId)
                            .centreName
                        }
                        readOnly
                      />
                    )}

                    <div>
                      <label
                        htmlFor="email"
                        className="block text-sm font-medium text-gray-700"
                      >
                        Email address
                      </label>
                      <div className="mt-1">
                        <Field
                          id="email"
                          name="email"
                          type="email"
                          className="block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm focus:border-yellow-500 focus:outline-none focus:ring-yellow-500 sm:text-sm"
                        />
                        <ErrorMessage
                          name="email"
                          component="div"
                          className="text-red-500 text-sm"
                        />
                      </div>
                    </div>

                    <div>
                      <label
                        htmlFor="password"
                        className="block text-sm font-medium text-gray-700"
                      >
                        Password
                      </label>
                      <div className="mt-1">
                        <Field
                          id="password"
                          name="password"
                          type="password"
                          className="block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm focus:border-yellow-500 focus:outline-none focus:ring-yellow-500 sm:text-sm"
                        />
                        <ErrorMessage
                          name="password"
                          component="div"
                          className="text-red-500 text-sm"
                        />
                      </div>
                    </div>

                    <div>
                      <label
                        htmlFor="confirmPassword"
                        className="block text-sm font-medium text-gray-700"
                      >
                        Confirm Password
                      </label>
                      <div className="mt-1">
                        <Field
                          id="confirmPassword"
                          name="confirmPassword"
                          type="password"
                          className="block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm focus:border-yellow-500 focus:outline-none focus:ring-yellow-500 sm:text-sm"
                        />
                        <ErrorMessage
                          name="confirmPassword"
                          component="div"
                          className="text-red-500 text-sm"
                        />
                      </div>
                    </div>

                    <div className="flex justify-center">
                      <button
                        type="submit"
                        className="w-full mt-4 flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-yellow-300 hover:bg-yellow-400 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-yellow-500"
                        disabled={formik.isSubmitting}
                      >
                        {formik.isSubmitting ? "Submitting..." : "Submit"}
                      </button>
                    </div>
                  </form>
                )}
              </Formik>
            </div>
          </div>
        </div>
      )}

      {screen == "EmployeeTable" && (
        <>
          <div className="">
            <div className="flex justify-end mx-4">
              <button
                className="btn btntheme"
                onClick={() => setaddUserModal(!addUserModal)}
              >
                Add user
              </button>
            </div>
            <div className="px-4 sm:px-6 lg:px-8">
              <div className="-mx-4 mt-2 overflow-hidden shadow ring-1 ring-black ring-opacity-5 sm:-mx-6 md:mx-0 md:rounded-lg">
                <table className="min-w-full divide-y divide-gray-300">
                  <thead className="bg-gray-50">
                    <tr>
                      <th
                        scope="col"
                        className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6"
                      >
                        Employee
                      </th>
                      <th
                        scope="col"
                        className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6"
                      >
                        Permissions
                      </th>
                      {permissions?.canUpdate || permissions?.canDelete ? (
                        <th
                          scope="col"
                          className="px-7 py-3.5 text-right text-sm font-semibold text-gray-900 lg:table-cell"
                        >
                          Actions
                        </th>
                      ) : null}
                    </tr>
                  </thead>
                  {centerUsers.length <= 0 && (
                    <tbody>
                      <tr>
                        <td className="text-base p-4 text-red-600">
                          No Users Found
                        </td>
                      </tr>
                    </tbody>
                  )}
                  <tbody className="divide-y divide-gray-200 bg-white">
                    {centerUsers.map((user, i) => (
                      <tr key={i}>
                        <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
                          {user.username}
                        </td>

                        {permissions?.canUpdate && (
                          <td
                            className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium sm:pl-6 text-blue-600 cursor-pointer"
                            onClick={() => {
                              setOpen(!open);
                              // console.log(user.id)
                              fetchUserPermissions(user.id);
                              setUserId(user.id);
                            }}
                          >
                            Manage permissions
                          </td>
                        )}

                        <td className="text-right px-4">
                          {permissions?.canUpdate && (
                            <span
                              className="mx-2"
                              onClick={() => {
                                setEditModal(true);
                                setEditModalData(user);
                              }}
                            >
                              <i className="fa-solid fa-pen-to-square"></i>
                            </span>
                          )}
                          {permissions?.canDelete && (
                            <span
                              className="mx-2"
                              onClick={() => {
                                setDeleteRecordType("user");
                                setDeleteRecord(user);
                                setDeleteAdminModal(true);
                              }}
                            >
                              <i className="fa-solid fa-trash-can"></i>
                            </span>
                          )}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </>
      )}

      {screen == "addAdmin" && (
        <>
          <div className="flex justify-end mx-2">
            <button
              className="btn btntheme"
              onClick={() => setAddAdminModal(!addAdminModal)}
            >
              Add FSO
            </button>
          </div>
          <table className="min-w-full divide-y divide-gray-300">
            <thead className="bg-gray-50">
              <tr>
                <th
                  scope="col"
                  className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6"
                >
                  Username
                </th>

                <th
                  scope="col"
                  className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6"
                >
                  Email
                </th>
                <th></th>

                {permissions?.canUpdate || permissions?.canDelete ? (
                  <th
                    scope="col"
                    className="px-7 py-3.5 text-right text-sm font-semibold text-gray-900 lg:table-cell"
                  >
                    Actions
                  </th>
                ) : null}
              </tr>
            </thead>
            <tbody className="divide-y divide-gray-200 bg-white">
              {centers.length <= 0 && (
                <tr>
                  <td className="text-base p-4">Loading...</td>
                </tr>
              )}
              {superAdmins.map((admin, i) => (
                <tr key={i}>
                  <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
                    {admin.username}
                  </td>
                  <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
                    {admin.email}
                  </td>
                  <td
                    className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-blue-600 sm:pl-6"
                    onClick={() => {
                      setManageAdminPermissions(!manageAdminPermissions);

                      fetchUserPermissions(admin.id, "admin");
                      setUserId(admin.id);
                    }}
                  >
                    Manage Permissions
                  </td>

                  {permissions?.canUpdate || permissions?.canDelete ? (
                    <td className="text-right px-4">
                      {permissions?.canUpdate && (
                        <span
                          className="mx-2"
                          onClick={() => {
                            setEditModalData(admin);

                            setEditModal(true);
                          }}
                        >
                          <i className="fa-solid fa-pen-to-square"></i>
                        </span>
                      )}
                      {permissions?.canDelete && (
                        <span
                          className="mx-2"
                          onClick={() => {
                            setDeleteRecord(admin);
                            setDeleteAdminModal(true);
                          }}
                        >
                          <i className="fa-solid fa-trash-can"></i>
                        </span>
                      )}
                    </td>
                  ) : null}
                </tr>
              ))}
            </tbody>
          </table>
        </>
      )}

      <Transition.Root show={open} as={Fragment}>
        <Dialog
          as="div"
          className="relative z-10"
          initialFocus={cancelButtonRef}
          onClose={() => {
            setOpen(!open);
            setUserPermissions([]);
            setInitVal({});
          }}
        >
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-800 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          <div className="fixed inset-0 z-10 overflow-y-auto">
            <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pt-5 pb-4 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-[50rem] sm:p-6">
                  {/* <div></div> */}

                  {/* for a user which does'nt have permissions */}

                  {modalType == "add" ? (
                    <ManagePermissions
                      initVal={initVal}
                      permissionsLoading={permissionsLoading}
                      open={open}
                      setOpen={setOpen}
                      userId={userId}
                      modules={userModules}
                      btnTxt={"Add"}
                      onSubmit={(val, { setSubmitting, setValues }) => {
                        let filtered = [];

                        // Iterate over each item in the items array
                        userModules.forEach(({ name }) => {
                          // Construct the key names based on the item
                          const viewKey = `view${name}`;
                          const createKey = `add${name}`;
                          const updateKey = `update${name}`;
                          const deleteKey = `delete${name}`;

                          // Construct the object for the current item
                          const currentItem = {
                            title: name,
                            canView: val[viewKey] || false,
                            canAdd: val[createKey] || false,
                            canUpdate: val[updateKey] || false,
                            canDelete: val[deleteKey] || false,
                            usersId: userId,
                          };

                          // Push the constructed object to the filtered array
                          filtered.push(currentItem);
                        });

                        Axios.post("/Permissions/AddRange", filtered)
                          .then((res) => {
                            setOpen(!open);
                            setSubmitting(false);
                            setInitVal({});
                          })
                          .catch((err) => {
                            setSubmitting(false);
                            toast.error(err);
                          });

                        // Output the filtered and transformed data
                      }}
                    />
                  ) : (
                    <ManagePermissions
                      initVal={initVal}
                      permissionsLoading={permissionsLoading}
                      open={open}
                      setOpen={setOpen}
                      userId={userId}
                      modules={userModules}
                      btnTxt={"Update"}
                      onSubmit={(
                        val,
                        { setSubmitting, resetForm, setValues }
                      ) => {
                        let filtered = [];

                        // Iterate over each item in the items array
                        userModules.forEach((item) => {
                          // Construct the key names based on the item
                          const viewKey = `view${item.name}`;
                          const createKey = `add${item.name}`;
                          const updateKey = `update${item.name}`;
                          const deleteKey = `delete${item.name}`;

                          // Construct the object for the current item
                          const currentItem = {
                            title: item.name,
                            canView: val[viewKey] || false,
                            canAdd: val[createKey] || false,
                            canUpdate: val[updateKey] || false,
                            canDelete: val[deleteKey] || false,
                            usersId: userId,
                          };

                          // Push the constructed object to the filtered array
                          filtered.push(currentItem);
                        });

                        const permissionMap = {};
                        userPermissions.forEach((permission) => {
                          permissionMap[permission.title] = permission.id;
                        });

                        // Map the ids from userPermissions to filtered array
                        const mappedFiltered = filtered.map((item) => {
                          return {
                            ...item,
                            id: permissionMap[item.title],
                          };
                        });

                        Axios.put("/Permissions/UpdateRange", mappedFiltered)
                          .then((res) => {
                            setOpen(!open);
                            setSubmitting(false);
                            setInitVal({});
                          })
                          .catch((err) => {
                            setSubmitting(false);
                          });
                      }}
                    />
                  )}

                  {/* for a user which have permissions and want to update or delete */}
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>

      <Transition.Root show={manageAdminPermissions} as={Fragment}>
        <Dialog
          as="div"
          className="relative z-10"
          initialFocus={cancelButtonRef}
          onClose={() => {
            setManageAdminPermissions(!manageAdminPermissions);
            setUserPermissions([]);
            setInitVal({});
          }}
        >
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-800 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          <div className="fixed inset-0 z-10 overflow-y-auto">
            <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pt-5 pb-4 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-[50rem] sm:p-6">
                  {/* <div></div> */}

                  {/* for a user which does'nt have permissions */}

                  {modalType == "add" ? (
                    <ManagePermissions
                      initVal={initVal}
                      permissionsLoading={permissionsLoading}
                      open={manageAdminPermissions}
                      setOpen={setManageAdminPermissions}
                      userId={userId}
                      modules={adminModules}
                      btnTxt={"Add"}
                      onSubmit={(val, { setSubmitting, setValues }) => {
                        let filtered = [];

                        // Iterate over each item in the items array
                        adminModules.forEach(({ name }) => {
                          // Construct the key names based on the item
                          const viewKey = `view${name}`;
                          const createKey = `add${name}`;
                          const updateKey = `update${name}`;
                          const deleteKey = `delete${name}`;

                          // Construct the object for the current item
                          const currentItem = {
                            title: name,
                            canView: val[viewKey] || false,
                            canAdd: val[createKey] || false,
                            canUpdate: val[updateKey] || false,
                            canDelete: val[deleteKey] || false,
                            usersId: userId,
                          };

                          // Push the constructed object to the filtered array
                          filtered.push(currentItem);
                        });

                        // console.log(filtered)
                        // Output the filtered and transformed data
                        Axios.post("/Permissions/AddRange", filtered)
                          .then((res) => {
                            setManageAdminPermissions(!manageAdminPermissions);
                            setSubmitting(false);
                            setInitVal({});
                          })
                          .catch((err) => {
                            setSubmitting(false);
                            toast.error(err);
                          });
                      }}
                    />
                  ) : (
                    <ManagePermissions
                      initVal={initVal}
                      permissionsLoading={permissionsLoading}
                      open={manageAdminPermissions}
                      setOpen={setManageAdminPermissions}
                      btnTxt={"Update"}
                      userId={userId}
                      modules={adminModules}
                      onSubmit={(val, { setSubmitting, setValues }) => {
                        let filtered = [];

                        // Iterate over each item in the items array
                        adminModules.forEach((item) => {
                          // Construct the key names based on the item
                          const viewKey = `view${item.name}`;
                          const createKey = `add${item.name}`;
                          const updateKey = `update${item.name}`;
                          const deleteKey = `delete${item.name}`;

                          // Construct the object for the current item
                          const currentItem = {
                            title: item.name,
                            canView: val[viewKey] || false,
                            canAdd: val[createKey] || false,
                            canUpdate: val[updateKey] || false,
                            canDelete: val[deleteKey] || false,
                            usersId: userId,
                          };

                          // Push the constructed object to the filtered array
                          filtered.push(currentItem);
                        });

                        const permissionMap = {};
                        userPermissions.forEach((permission) => {
                          permissionMap[permission.title] = permission.id;
                        });

                        // Map the ids from userPermissions to filtered array
                        const mappedFiltered = filtered.map((item) => {
                          return {
                            ...item,
                            id: permissionMap[item.title],
                          };
                        });

                        Axios.put("/Permissions/UpdateRange", mappedFiltered)
                          .then((res) => {
                            setManageAdminPermissions(!manageAdminPermissions);
                            setSubmitting(false);
                            setInitVal({});
                          })
                          .catch((err) => {
                            setSubmitting(false);
                          });
                      }}
                    />
                  )}

                  {/* for a user which have permissions and want to update or delete */}
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>

      <Modal
        title={"Edit Modal"}
        open={editModal}
        setOpen={setEditModal}
        width={"max-w-2xl"}
        content={
          <>
            <div className="flex min-h-full flex-col justify-center  sm:px-6 lg:px-8">
              <div className="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
                <div className="flex justify-center">
                  <span>Update user</span>
                </div>
                <div className="bg-white py-8 px-4 shadow-md sm:rounded-lg sm:px-10">
                  <Formik
                    initialValues={{
                      id: editModalData.id,
                      name: editModalData.name || "",
                      username: editModalData.username || "",
                      password: "",
                      confirmPassword: "",
                      email: editModalData.email || "",
                      roleId: "5bfa6baf-8baa-4c13-f442-08dcbdbd2f66",
                    }}
                    validationSchema={yup.object().shape({
                      username: yup
                        .string()
                        .min(3, "Username must be at least 3 characters")
                        .max(20, "Username can't exceed 20 characters")
                        .required("Username is required"),

                      email: yup
                        .string()
                        .matches(
                          /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
                          "Invalid email format"
                        )
                        .required("Email is required"),

                      password: yup
                        .string()
                        .min(8, "Password must be at least 8 characters")
                        .matches(
                          /[A-Z]/,
                          "Password must contain an uppercase letter"
                        )
                        .matches(
                          /[a-z]/,
                          "Password must contain a lowercase letter"
                        )
                        .matches(/[0-9]/, "Password must contain a number")
                        .matches(
                          /[@$!%*?&#]/,
                          "Password must contain a special character"
                        )
                        .required("Password is required"),

                      confirmPassword: yup
                        .string()
                        .oneOf(
                          [yup.ref("password"), null],
                          "Passwords must match"
                        )
                        .required("Please confirm your password"),
                    })}
                    onSubmit={(
                      values,
                      { resetForm, setFieldError, setSubmitting }
                    ) => {
                      const updatePass = {
                        userId: values["id"],
                        email: values["email"],
                        password: values["password"],
                      };

                      const hasDetailsChanged = values["username"] !== "";
                      const isPassUpdate = values["password"] !== "";

                      const detailsToUpdate = { ...values };
                      delete detailsToUpdate["password"];

                      if (hasDetailsChanged && isPassUpdate) {
                        Axios.put("/Users/Update", detailsToUpdate)
                          .then((res) => {
                            return Axios.post(
                              "/Users/ResetPassword",
                              updatePass
                            )
                              .then((resetPassRes) => {
                                toast.success(
                                  "User updated successfully and password reset."
                                );
                                fetchCenterUserId();
                              })
                              .catch((err) => {
                                setEditModal(false);
                                setSubmitting(false);
                                toast.error(
                                  "An error occurred while updating password"
                                );
                                throw err; // Rethrow to ensure any outer catch block handles it
                              });
                          })
                          .then(() => {
                            setSubmitting(false);
                            setEditModal(false); // Only set edit modal to false after fetchCentereUsers completes
                          })
                          .catch((err) =>
                            setFieldError("username", err.response.data)
                          );
                      } else if (hasDetailsChanged) {
                        Axios.put("/Users/Update", detailsToUpdate)
                          .then(() => {
                            setEditModal(false);
                            toast.success("User Updated Successfully");
                          })
                          .catch((err) =>
                            setFieldError("username", err.response.data)
                          )
                          .finally(() => setSubmitting(false));
                      } else if (isPassUpdate) {
                        Axios.post("/Users/ResetPassword", updatePass)
                          .then((resetPassRes) => {
                            toast.success("Password Updated Successfully");
                          })
                          .catch((err) => {
                            setEditModal(false);
                            setSubmitting(false);
                            toast.error(
                              "An error occurred while updating password"
                            );
                            throw err; // Rethrow to ensure any outer catch block handles it
                          });
                      }
                    }}
                    className="space-y-6"
                  >
                    {(formik) => (
                      <form
                        onSubmit={formik.handleSubmit}
                        className="space-y-6"
                      >
                        <div>
                          <label
                            htmlFor="username"
                            className="block text-sm font-medium text-gray-700"
                          >
                            Username
                          </label>
                          <div className="mt-1">
                            <Field
                              id="username"
                              name="username"
                              type="text"
                              className="block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm focus:border-yellow-500 focus:outline-none focus:ring-yellow-500 sm:text-sm"
                            />
                            <ErrorMessage
                              name="username"
                              component="div"
                              className="text-red-500 text-sm"
                            />
                          </div>
                        </div>

                        <div>
                          <label
                            htmlFor="email"
                            className="block text-sm font-medium text-gray-700"
                          >
                            Email address
                          </label>
                          <div className="mt-1">
                            <Field
                              id="email"
                              name="email"
                              type="email"
                              className="block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm focus:border-yellow-500 focus:outline-none focus:ring-yellow-500 sm:text-sm"
                            />
                            <ErrorMessage
                              name="email"
                              component="div"
                              className="text-red-500 text-sm"
                            />
                          </div>
                        </div>

                        {role !== "employee" && (
                          <>
                            <div>
                              <label
                                htmlFor="password"
                                className="block text-sm font-medium text-gray-700"
                              >
                                New Password
                              </label>
                              <div className="mt-1">
                                <Field
                                  id="password"
                                  name="password"
                                  type="password"
                                  className="block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm focus:border-yellow-500 focus:outline-none focus:ring-yellow-500 sm:text-sm"
                                />
                                <ErrorMessage
                                  name="password"
                                  component="div"
                                  className="text-red-500 text-sm"
                                />
                              </div>
                            </div>

                            <div>
                              <label
                                htmlFor="confirmPassword"
                                className="block text-sm font-medium text-gray-700"
                              >
                                Confirm Password
                              </label>
                              <div className="mt-1">
                                <Field
                                  id="confirmPassword"
                                  name="confirmPassword"
                                  type="password"
                                  className="block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm focus:border-yellow-500 focus:outline-none focus:ring-yellow-500 sm:text-sm"
                                />
                                <ErrorMessage
                                  name="confirmPassword"
                                  component="div"
                                  className="text-red-500 text-sm"
                                />
                              </div>
                            </div>
                          </>
                        )}

                        <div className="flex justify-center">
                          <button
                            type="submit"
                            className="w-full mt-4 flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-yellow-300 hover:bg-yellow-400 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-yellow-500"
                            disabled={!formik.dirty || formik.isSubmitting}
                          >
                            {formik.isSubmitting ? "Submitting..." : "Submit"}
                          </button>
                        </div>
                      </form>
                    )}
                  </Formik>
                </div>
              </div>
            </div>
          </>
        }
      />

      <Modal
        title={"Edit Modal"}
        open={editCenterModal}
        setOpen={setEditCenterModal}
        width={"max-w-2xl"}
        content={
          <>
            <div className="flex min-h-full flex-col justify-center  sm:px-6 lg:px-8">
              <div className="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
                <div className="flex justify-center">
                  <span>Update Center</span>
                </div>
                <div className="bg-white py-8 px-4 shadow-md sm:rounded-lg sm:px-10">
                  <Formik
                    initialValues={{
                      userId: centerUserId,
                      email: editModalData.email || "",
                      password: "",
                      confirmPassword: "",
                    }}
                    validationSchema={yup.object().shape({
                      password: yup
                        .string()
                        .min(8, "Password must be at least 8 characters")
                        .matches(
                          /[A-Z]/,
                          "Password must contain an uppercase letter"
                        )
                        .matches(
                          /[a-z]/,
                          "Password must contain a lowercase letter"
                        )
                        .matches(/[0-9]/, "Password must contain a number")
                        .matches(
                          /[@$!%*?&#]/,
                          "Password must contain a special character"
                        )
                        .required("Password is required"),

                      confirmPassword: yup
                        .string()
                        .oneOf(
                          [yup.ref("password"), null],
                          "Passwords must match"
                        )
                        .required("Please confirm your password"),
                    })}
                    onSubmit={(values, { resetForm, setSubmitting }) => {
                      Axios.post("/Users/ResetPassword", values)
                        .then((resetPassRes) => {
                          toast.success(
                            "User updated successfully and password reset."
                          );
                        })
                        .then(() => {
                          setEditCenterModal(false);
                          setSubmitting(false);
                        })
                        .catch((err) => {
                          setEditCenterModal(false);
                          setSubmitting(false);
                          toast.error(
                            "An error occurred while updating password"
                          );
                          throw err; // Rethrow to ensure any outer catch block handles it
                        });
                    }}
                    className="space-y-6"
                  >
                    {(formik) => (
                      <form
                        onSubmit={formik.handleSubmit}
                        className="space-y-6"
                      >
                        <div>
                          <label
                            htmlFor="name"
                            className="block text-sm font-medium text-gray-700"
                          >
                            Name
                          </label>
                          <div className="mt-1">
                            <input
                              value={editModalData.name}
                              type="text"
                              disabled
                              className="block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm focus:border-yellow-500 focus:outline-none focus:ring-yellow-500 sm:text-sm "
                            />
                          </div>
                        </div>

                        <div>
                          <label
                            htmlFor="password"
                            className="block text-sm font-medium text-gray-700"
                          >
                            New Password
                          </label>
                          <div className="mt-1">
                            <Field
                              id="password"
                              name="password"
                              type="password"
                              className="block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm focus:border-yellow-500 focus:outline-none focus:ring-yellow-500 sm:text-sm"
                            />
                            <ErrorMessage
                              name="password"
                              component="div"
                              className="text-red-500 text-sm"
                            />
                          </div>
                        </div>

                        <div>
                          <label
                            htmlFor="confirmPassword"
                            className="block text-sm font-medium text-gray-700"
                          >
                            Confirm Password
                          </label>
                          <div className="mt-1">
                            <Field
                              id="confirmPassword"
                              name="confirmPassword"
                              type="password"
                              className="block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm focus:border-yellow-500 focus:outline-none focus:ring-yellow-500 sm:text-sm"
                            />
                            <ErrorMessage
                              name="confirmPassword"
                              component="div"
                              className="text-red-500 text-sm"
                            />
                          </div>
                        </div>

                        <div className="flex justify-center">
                          <button
                            type="submit"
                            className="w-full mt-4 flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-yellow-300 hover:bg-yellow-400 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-yellow-500"
                            disabled={formik.isSubmitting}
                          >
                            {formik.isSubmitting ? "Submitting..." : "Submit"}
                          </button>
                        </div>
                      </form>
                    )}
                  </Formik>
                </div>
              </div>
            </div>
          </>
        }
      />

      <Modal
        open={addAdminModal}
        setOpen={setAddAdminModal}
        width={"max-w-2xl"}
        content={
          <div className="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
            <div className="flex justify-center">
              <span>Add Admin</span>
            </div>
            <div className="bg-white py-8 px-4 shadow-md sm:rounded-lg sm:px-10">
              <Formik
                initialValues={{
                  name: "",
                  username: "",
                  password: "",
                  confirmPassword: "",
                  email: "",
                  roleId: "3fa85f64-5717-4562-b3fc-2c963f66afa6",
                }}
                validationSchema={yup.object().shape({
                  username: yup
                    .string()
                    .min(3, "Username must be at least 3 characters")
                    .max(20, "Username can't exceed 20 characters")
                    .required("Username is required"),

                  email: yup
                    .string()
                    .matches(
                      /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
                      "Invalid email format"
                    )
                    .required("Email is required"),

                  password: yup
                    .string()
                    .min(8, "Password must be at least 8 characters")
                    .matches(
                      /[A-Z]/,
                      "Password must contain an uppercase letter"
                    )
                    .matches(
                      /[a-z]/,
                      "Password must contain a lowercase letter"
                    )
                    .matches(/[0-9]/, "Password must contain a number")
                    .matches(
                      /[@$!%*?&#]/,
                      "Password must contain a special character"
                    )
                    .required("Password is required"),

                  confirmPassword: yup
                    .string()
                    .oneOf([yup.ref("password"), null], "Passwords must match")
                    .required("Please confirm your password"),
                })}
                onSubmit={(
                  values,
                  { resetForm, setFieldError, setSubmitting }
                ) => {
                  Axios.post("/Users/Register", values)
                    .then((res) => {
                      toast.success("User created successfully");
                      setAddAdminModal(false);
                      fetchSuperAdmins();
                      resetForm();
                    })
                    .catch((err) => {
                      setFieldError("username", err.response.data);
                    })
                    .finally(() => {
                      setSubmitting(false);
                    });
                }}
                className="space-y-6"
              >
                {(formik) => (
                  <form onSubmit={formik.handleSubmit} className="space-y-6">
                    <div>
                      <label
                        htmlFor="username"
                        className="block text-sm font-medium text-gray-700"
                      >
                        Username
                      </label>
                      <div className="mt-1">
                        <Field
                          id="username"
                          name="username"
                          type="text"
                          className="block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm focus:border-yellow-500 focus:outline-none focus:ring-yellow-500 sm:text-sm"
                        />
                        <ErrorMessage
                          name="username"
                          component="div"
                          className="text-red-500 text-sm"
                        />
                      </div>
                    </div>

                    <div>
                      <label
                        htmlFor="email"
                        className="block text-sm font-medium text-gray-700"
                      >
                        Email address
                      </label>
                      <div className="mt-1">
                        <Field
                          id="email"
                          name="email"
                          type="email"
                          className="block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm focus:border-yellow-500 focus:outline-none focus:ring-yellow-500 sm:text-sm"
                        />
                        <ErrorMessage
                          name="email"
                          component="div"
                          className="text-red-500 text-sm"
                        />
                      </div>
                    </div>

                    <div>
                      <label
                        htmlFor="password"
                        className="block text-sm font-medium text-gray-700"
                      >
                        Password
                      </label>
                      <div className="mt-1">
                        <Field
                          id="password"
                          name="password"
                          type="password"
                          className="block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm focus:border-yellow-500 focus:outline-none focus:ring-yellow-500 sm:text-sm"
                        />
                        <ErrorMessage
                          name="password"
                          component="div"
                          className="text-red-500 text-sm"
                        />
                      </div>
                    </div>

                    <div>
                      <label
                        htmlFor="confirmPassword"
                        className="block text-sm font-medium text-gray-700"
                      >
                        Confirm Password
                      </label>
                      <div className="mt-1">
                        <Field
                          id="confirmPassword"
                          name="confirmPassword"
                          type="password"
                          className="block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm focus:border-yellow-500 focus:outline-none focus:ring-yellow-500 sm:text-sm"
                        />
                        <ErrorMessage
                          name="confirmPassword"
                          component="div"
                          className="text-red-500 text-sm"
                        />
                      </div>
                    </div>

                    <div className="flex justify-center">
                      <button
                        type="submit"
                        className="w-full mt-4 flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-yellow-300 hover:bg-yellow-400 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-yellow-500"
                        disabled={formik.isSubmitting}
                      >
                        {formik.isSubmitting ? "Submitting..." : "Submit"}
                      </button>
                    </div>
                  </form>
                )}
              </Formik>
            </div>
          </div>
        }
      />

      <Modal
        open={addUserModal}
        setOpen={setaddUserModal}
        width={"max-w-2xl"}
        content={
          <div className="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
            <div className="flex justify-center">
              <span>Add User</span>
            </div>
            <div className="bg-white py-8 px-4 shadow-md sm:rounded-lg sm:px-10">
              <Formik
                initialValues={addUserInitVal}
                validationSchema={yup.object().shape({
                  username: yup
                    .string()
                    .min(3, "Username must be at least 3 characters")
                    .max(20, "Username can't exceed 20 characters")
                    .required("Username is required"),

                  email: yup
                    .string()
                    .matches(
                      /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
                      "Invalid email format"
                    )
                    .required("Email is required"),

                  password: yup
                    .string()
                    .min(8, "Password must be at least 8 characters")
                    .matches(
                      /[A-Z]/,
                      "Password must contain an uppercase letter"
                    )
                    .matches(
                      /[a-z]/,
                      "Password must contain a lowercase letter"
                    )
                    .matches(/[0-9]/, "Password must contain a number")
                    .matches(
                      /[@$!%*?&#]/,
                      "Password must contain a special character"
                    )
                    .required("Password is required"),

                  confirmPassword: yup
                    .string()
                    .oneOf([yup.ref("password"), null], "Passwords must match")
                    .required("Please confirm your password"),
                })}
                onSubmit={(
                  values,
                  { resetForm, setFieldError, setSubmitting }
                ) => {
                  Axios.post("/Users/Register", values)
                    .then((res) => {
                      toast.success("User created successfully");
                      resetForm();
                      setSubmitting(false);
                      setaddUserModal(false);
                      fetchCentereUsers(centerId);
                    })
                    .catch((err) => {
                      try {
                        if (err.response.data == "Usersname Already Exists") {
                          setFieldError("username", err.response.data);
                        } else {
                          console.log(err.message);
                          toast.error("An error occured while creating a user");
                        }
                      } catch (error) {}
                      setSubmitting(false);
                    });
                }}
                className="space-y-6"
              >
                {(formik) => (
                  <form onSubmit={formik.handleSubmit} className="space-y-6">
                    <div>
                      <label
                        htmlFor="username"
                        className="block text-sm font-medium text-gray-700"
                      >
                        Username
                      </label>
                      <div className="mt-1">
                        <Field
                          id="username"
                          name="username"
                          type="text"
                          className="block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm focus:border-yellow-500 focus:outline-none focus:ring-yellow-500 sm:text-sm"
                        />
                        <ErrorMessage
                          name="username"
                          component="div"
                          className="text-red-500 text-sm"
                        />
                      </div>
                    </div>

                    <div>
                      <label
                        htmlFor="email"
                        className="block text-sm font-medium text-gray-700"
                      >
                        Email address
                      </label>
                      <div className="mt-1">
                        <Field
                          id="email"
                          name="email"
                          type="email"
                          className="block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm focus:border-yellow-500 focus:outline-none focus:ring-yellow-500 sm:text-sm"
                        />
                        <ErrorMessage
                          name="email"
                          component="div"
                          className="text-red-500 text-sm"
                        />
                      </div>
                    </div>

                    <div>
                      <label
                        htmlFor="password"
                        className="block text-sm font-medium text-gray-700"
                      >
                        Password
                      </label>
                      <div className="mt-1">
                        <Field
                          id="password"
                          name="password"
                          type="password"
                          className="block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm focus:border-yellow-500 focus:outline-none focus:ring-yellow-500 sm:text-sm"
                        />
                        <ErrorMessage
                          name="password"
                          component="div"
                          className="text-red-500 text-sm"
                        />
                      </div>
                    </div>

                    <div>
                      <label
                        htmlFor="confirmPassword"
                        className="block text-sm font-medium text-gray-700"
                      >
                        Confirm Password
                      </label>
                      <div className="mt-1">
                        <Field
                          id="confirmPassword"
                          name="confirmPassword"
                          type="password"
                          className="block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm focus:border-yellow-500 focus:outline-none focus:ring-yellow-500 sm:text-sm"
                        />
                        <ErrorMessage
                          name="confirmPassword"
                          component="div"
                          className="text-red-500 text-sm"
                        />
                      </div>
                    </div>

                    <div className="flex justify-center">
                      <button
                        type="submit"
                        className="w-full mt-4 flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-yellow-300 hover:bg-yellow-400 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-yellow-500"
                        disabled={formik.isSubmitting}
                      >
                        {formik.isSubmitting ? "Submitting..." : "Submit"}
                      </button>
                    </div>
                  </form>
                )}
              </Formik>
            </div>
          </div>
        }
      />

      <DeleteModal
        modal={deleteAdminModal}
        setModal={setDeleteAdminModal}
        deleteFunc={() => {
          setDeleteLoading(true);
          Axios.delete(`Users/Remove?id=${deleteRecord.id}`)
            .then((res) => {})
            .catch((err) => {
              toast.error(err);
            })
            .finally(() => {
              if (deleteRecordType == "admin") {
                fetchSuperAdmins().then(() => {
                  setDeleteAdminModal(false);
                  setDeleteLoading(false);
                });
              } else {
                fetchCentereUsers(centerId).then(() => {
                  setDeleteAdminModal(false);
                  setDeleteLoading(false);
                });
              }
            });
        }}
        loading={deleteLoading}
      />
    </>
  );
};

export default Index;
