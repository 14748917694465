import React, { useEffect, useState } from "react";
import { Axios, contentUrl } from "../../Services/Axios";
import ReactPaginate from "react-paginate";
import { decryptData, Storage } from "../../Services/Storage";
import { useSelector } from "react-redux";

const Orders = () => {
  // Get current date
  const currentDate = new Date();
  // Format the current date to YYYY-MM format
  const currentMonth = `${currentDate.getFullYear()}-${(
    currentDate.getMonth() + 1
  )
    .toString()
    .padStart(2, "0")}`;

  const [selectedMonth, setSelectedMonth] = useState(currentMonth);

  const center = useSelector((e) => e.Center);
  const [loading, setLoading] = useState(false);
  const [formValues, setFormValues] = useState([]);
  const pageCount = Math.ceil(formValues.length / 10);
  const [itemOffset, setItemOffset] = useState(0);
  const [filtered, setFiltered] = React.useState([]);
  const [statuses, setStatuses] = React.useState({});
  const [checkedRows, setCheckedRows] = React.useState([]);
  const [cid, setId] = useState(null);
  const [csvloader, setCSVLoader] = useState(false);

  const { centresId } = Storage.getItem("user");

  // const getOrders = (id) => {
  //   Axios.get(`/Order/GetByCenter?CenterId=${id}`).then((res) => {
  //     setLoading(false);
  //     setFormValues(res.data?.reverse());
  //   });
  // };

  useEffect(() => {
    if (Boolean(center)) {
      //handleFilterMethod(decryptData(center)?.id);
      setId(decryptData(center)?.id);
    }
  }, [center]);

  useEffect(() => {
    const endOffset = itemOffset + 10;
    console.log(`Loading items from ${itemOffset} to ${endOffset}`);
    setFiltered(formValues.slice(itemOffset, endOffset));
  }, [itemOffset, formValues]);

  const handlePage = (event) => {
    const newOffset = (event.selected * 10) % formValues.length;
    setItemOffset(newOffset);
  };

  const [expandedRow, setExpandedRow] = useState(null);

  const toggleRow = (index) => {
    if (expandedRow === index) {
      setExpandedRow(null);
    } else {
      setExpandedRow(index);
    }
  };

  const handleFilterMethod = () => {
    setLoading(true);
    Axios.get(
      `/Order/GetByCenterMOnthWise?CenterId=${centresId}&startDate=${selectedMonth}`
    ).then((res) => {
      setLoading(false);
      setFormValues(res.data);
    });
  };

  const handleExport = () => {
    setCSVLoader(true);
    Axios.get(
      `/OrderItems/GetCsv?Status=&CampusId=${centresId}&startDate=${selectedMonth}`
    ).then((res) => {
      // console.log(res.data);
      const blob = new Blob([res.data], { type: "text/csv" });
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "data.csv");
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      window.URL.revokeObjectURL(url);
      setCSVLoader(false);
    });
  };

  return (
    <>
      <div className="table mt-5">
        <div className="mt-1 mr-5 ml-5">
          <div className="flex items-center space-x-4 mb-4">
            <input
              type="month"
              id="month"
              className="form-input"
              name="month"
              value={selectedMonth}
              onChange={(e) => {
                setSelectedMonth(e.target.value); // Adding 1 to get month number from 1 to 12
              }}
            />
            <button className="btn btntheme " onClick={handleFilterMethod}>
              {loading ? "Please wait..." : "Order List"}
            </button>
            {filtered.length > 0 && (
              <button className="btn btntheme" onClick={handleExport}>
                {csvloader ? "Exporting..." : "Export CSV"}
              </button>
            )}
          </div>
        </div>

        <div className="p-4">
          <div className="table border border-gray-200 rounded">
            <div className="flex tableheader justify-between">
              <div>Order ID</div>
              {/* <div>Course Name</div> */}
              {/* <div>Semester Name</div> */}
              <div>Order By</div>
              <div>Books Quantity</div>
              <div>Total Price</div>

              <div>Status</div>
              <div>Order Date</div>
              <div>Action</div>
            </div>

            <div className="tablebody">
              {/* {filtered.length <=0 && <div>No Record Found</div>} */}
              {filtered.map((order, index) => (
                <React.Fragment key={order.id}>
                  <div className="flex w-full justify-between">
                    <div>
                      <span className="my-des-alt">{order.orderId}</span>
                    </div>
                    {/* <div>
                  <span className="my-des-alt">{order.course}</span>
                </div> */}
                    {/* <div>
                  <span className="my-des-alt">{order.semester}</span>
                </div> */}
                    <div>
                      <span className="my-des-alt">{order.orderBy}</span>
                    </div>
                    <div>
                      <span className="my-des-alt">{order.totalCount}</span>
                    </div>
                    <div>
                      <span className="my-des-alt">{order.totalPrice}</span>
                    </div>

                    <div>
                      <span className="my-des-alt">
                        {order.currentStatus?.toUpperCase()}
                      </span>
                    </div>
                    <div>
                      <span className="my-des-alt">
                        {order?.createdDate?.split("T")[0]}
                      </span>
                    </div>
                    <div>
                      <span
                        style={{ cursor: "pointer" }}
                        onClick={() => toggleRow(index)}
                      >
                        <i className="fa fa-eye" aria-hidden="true"></i>
                      </span>
                      <span style={{ marginLeft: 10 }}>
                        <a
                          href={contentUrl + order?.paymentSlip}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <i className="fa-solid fa-receipt"></i>
                        </a>
                      </span>
                    </div>
                  </div>
                  {expandedRow === index && (
                    <>
                      <table border={1} style={{ width: "100%" }}>
                        <thead>
                          <th>book name</th>
                          <th>course</th>
                          <th>semester</th>
                          <th>quantity</th>
                          <th>unit price</th>
                          <th>total price</th>
                        </thead>
                        <tbody>
                          {order?.orderItems.map((e) => (
                            <tr style={{ textAlign: "center" }}>
                              <td>{e?.bookname}</td>
                              <td>{e?.course}</td>
                              <td>{e?.semester}</td>
                              <td>{e?.orderQTY}</td>
                              <td>{e?.unitPrice}</td>
                              <td>{e?.totalPrice}</td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </>
                  )}
                </React.Fragment>
              ))}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Orders;
