import React from "react";
import { Axios } from "../../Services/Axios/index";
import * as xlsx from "xlsx";
import ReactPaginate from "react-paginate";
import { useSelector } from "react-redux";
import { decryptData, Storage } from "../../Services/Storage";
import { act } from "@testing-library/react";
import { fetchPermissions } from "../../Components/sidebar";
import { toast } from "react-toastify";

export const Inquiry = () => {
  const center = useSelector((e) => e.Center);
  const [dataValues, setdataValues] = React.useState([]);
  const [inquiry, setInquiry] = React.useState([]);
  const [loading, setLoading] = React.useState(true);
  const [filtered, setFiltered] = React.useState([]);
  const [permissions, setPermissions] = React.useState([]);
  const [itemOffset, setItemOffset] = React.useState(0);
  const [pageCount, setPageCount] = React.useState(0);
  const { id, role, centresId } = Storage.getItem("user");

  React.useEffect(() => {
    const endOffset = itemOffset + 10;
    let tt = [...dataValues];
    // console.log(`Loading items from ${itemOffset} to ${endOffset}`);
    setFiltered(tt.slice(itemOffset, endOffset));
  }, [itemOffset, dataValues]);

  const handlePage = (event) => {
    const newOffset = (event.selected * 10) % dataValues.length;
    setItemOffset(newOffset);
  };
  React.useEffect(() => {
    fetchPermissions(id)
      .then((res) => {
        const permission = res.find((item) => item.title == "Events");
        // console.log(permission);
        setPermissions(permission);
      })
      .catch((err) => {
        toast.error("Uncaught error");
      });
  }, []);

  React.useEffect(() => {
    GetAllInquiry();
  }, [center]);

  const GetAllInquiry = () => {
    setLoading(true);
    if (role == "admin") {
      Axios.get(
        `/Inquiries/GetByCenterId?Id=${
          Boolean(center)
            ? decryptData(center)?.id
            : "00000000-0000-0000-0000-000000000000"
        }`
      ).then(
        (res) => {
          setInquiry([]);
          let a = res.data?.reverse();
          a.forEach((x) => {
            let payload = {
              Name: x.uname,
              Phone: x.uphne,
              Email: x.uemail,
              City: x.currentCity,
              Course: x.course,
              Center: x.centres ? x.centres : "---",
              Date: x.createdDate ? x.createdDate?.split("T")[0] : "---",
            };
            setInquiry((p) => [...p, payload]);
            x.centres = x.centres ? x.centres : "---";
          });
          setdataValues(a);
          setPageCount(Math.ceil(res.data.length / 10));
          setLoading(false);
        },
        () => {}
      );
    } else {
      Axios.get(`/Inquiries/GetByCenterId?Id=${centresId}`).then(
        (res) => {
          setInquiry([]);
          let a = res.data?.reverse();
          a.forEach((x) => {
            let payload = {
              Name: x.uname,
              Phone: x.uphne,
              Email: x.uemail,
              City: x.currentCity,
              Course: x.course,
              Center: x.centres ? x.centres : "---",
              Date: x.createdDate ? x.createdDate?.split("T")[0] : "---",
            };
            setInquiry((p) => [...p, payload]);
            x.centres = x.centres ? x.centres : "---";
          });
          setdataValues(a);
          setPageCount(Math.ceil(res.data.length / 10));
          setLoading(false);
        },
        () => {}
      );
    }
  };

  const Excel = () => {
    let book = xlsx.utils.book_new();
    let sheet = xlsx.utils.json_to_sheet(inquiry);
    xlsx.utils.book_append_sheet(book, sheet);
    xlsx.writeFile(book, "Course Inquiry.xlsx");
  };

  return (
    <>
      <div className="pageTitle mx-4 my-4">
        <div>
          <h3>Course Inquiry</h3>
        </div>
        <button
          className="btn btntheme"
          title="Export Excel"
          type="button"
          onClick={Excel}
        >
          Export
        </button>
      </div>

      <div className="p-4">
        <div className="table inquiry border border-gray-200 rounded">
          <div className="flex tableheader !px-5 !space-x-5">
            <div>Name</div>
            <div>Phone</div>
            <div>Email</div>
            <div>Course</div>
            <div>Center</div>
            <div>Date</div>
          </div>
          <div className="tablebody">
            {loading && <p className="p-4">Loading...</p>}
            {!loading && dataValues.length === 0 && <p className="p-4">No data found.</p>}
            {!loading && dataValues.length > 0 && (
              <>
                {filtered.map((data, i) => (
                  <div className="flex" key={i}>
                    <div>{data.uname}</div>
                    <div>{data.uphne}</div>
                    <div className="text-ellipsis overflow-clip">{data.uemail}</div>
                    <div>{data.course}</div>
                    <div>{data.centres}</div>
                    <div>{data.createdDate?.split("T")[0]}</div>
                  </div>
                ))}
                <ReactPaginate
                  breakLabel="..."
                  nextLabel="&raquo;"
                  onPageChange={handlePage}
                  pageRangeDisplayed={5}
                  pageCount={pageCount}
                  previousLabel="&laquo;"
                  renderOnZeroPageCount={null}
                  containerClassName="mars-pagination"
                  pageClassName="mars-page"
                  nextClassName="mars-page"
                  previousClassName="mars-page"
                  activeClassName="active"
                />
              </>
            )}
          </div>
        </div>
      </div>
    </>
  );
};
