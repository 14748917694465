import React, { useEffect, useState } from "react";
// import axios from 'axios';

import * as Yup from "yup";

import { toast } from "react-toastify";
import { Axios } from "../../Services/Axios";
import { useFormik } from "formik";
import ReactPaginate from "react-paginate";
import { Storage } from "../../Services/Storage";
import { fetchPermissions } from "../../Components/sidebar";

export const Cities = () => {
  const [formValues, setFormValues] = useState([]);
  const [loading, setLoading] = useState(false);
  const [filtered, setFiltered] = React.useState([]);
  const [permissions, setPermissions] = useState([]);
  const [itemOffset, setItemOffset] = useState(0);
  const pageCount = Math.ceil(formValues.length / 10);
  const id = Storage.getItem("user").id;
  useEffect(() => {
    const endOffset = itemOffset + 10;
    console.log(`Loading items from ${itemOffset} to ${endOffset}`);
    setFiltered(formValues.slice(itemOffset, endOffset));
  }, [itemOffset, formValues]);

  const handlePage = (event) => {
    const newOffset = (event.selected * 10) % formValues.length;
    setItemOffset(newOffset);
  };
  const initialValues = {
    name: "",
    id: null,
  };

  useEffect(() => {
    fetchPermissions(id)
      .then((res) => {
        const permission = res.find((item) => item.title == "Cities");
        // console.log(permission);
        setPermissions(permission);
      })
      .catch((err) => {
        toast.error("Uncaught error");
      });
    GetAllCenters();
  }, []);

  const GetAllCenters = () => {
    setLoading(true);
    Axios.get("/City/GetAll").then(
      (res) => {
        setLoading(false);
        setFormValues(res.data);
      },
      () => {}
    );
  };

  const Edit = (r) => {
    formik.setValues(r);
    openModal();
  };
  const Delete = (id) => {
    Axios.delete(`/City/Remove?id=${id}`).then(
      () => {
        setFormValues((e) => [...e.filter((x) => x.id !== id)]);
        toast.success("Data Deleted Successfully...");
      },
      () => {
        toast.error("Something went wrong...");
      }
    );
  };

  const [open, setOpen] = useState(false);
  const openModal = () => {
    console.log(open);
    if (open) {
      formik.resetForm();
    }
    setOpen(!open);
  };

  const validationSchema = Yup.object().shape({
    name: Yup.string().required("Name is required"),
  });
  const handleSubmit = async (values, { resetForm }) => {
    const payload = new FormData();
    const keys = Object.keys(values);
    for (let i = 0; i < keys.length; i++) {
      const key = keys[i];
      if (values[key]) {
        payload.append(key, values[key]);
      }
    }
    console.log(formik.values);
    if (formik.values.id) {
      Axios.put("/City/Update", payload, {
        headers: { "Content-Type": "multipart/form-data" },
      }).then(
        (res) => {
          setFormValues([
            ...formValues.filter((x) => x.id === res.data.id),
            res.data,
          ]);
          resetForm();
          setOpen(!open);
          toast.success("Data Updated successfully");
        },
        (err) => {
          toast.error("Something went wrong...");
        }
      );
    } else {
      Axios.post("/City/Add", payload, {
        headers: { "Content-Type": "multipart/form-data" },
      }).then(
        (res) => {
          setFormValues([...formValues, res.data]);
          resetForm();
          setOpen(!open);
          toast.success("Data inserted successfully");
        },
        (err) => {
          toast.error("Something went wrong...");
        }
      );
    }
  };

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: handleSubmit,
    enableReinitialize: true,
  });

  return (
    <>
      <div className="pageTitle mx-4 my-4">
        <div>
          <h3>Cities</h3>
        </div>
        {permissions.canAdd && (
          <button className="btn btntheme" onClick={openModal}>
            Add City
          </button>
        )}
      </div>
      <div className="p-4">
        <div className="table border border-gray-200 rounded">
          <div className="tablebody">
            {loading && <p className="p-4">Loading...</p>}
            {!loading && formValues.length === 0 && <p className="p-4">No data found.</p>}
            {!loading && formValues.length > 0 && (
              <table className="min-w-full divide-y divide-gray-300">
                <thead className="bg-[#ededed]">
                  <tr>
                    <th
                      scope="col"
                      className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6"
                    >
                      Name
                    </th>

                    <th
                      scope="col"
                      className="text-right relative py-3.5 pl-3 pr-4 sm:pr-6"
                    >
                      Action
                    </th>
                  </tr>
                </thead>
                <tbody className="divide-y divide-gray-200 bg-white ml-4">
                  {filtered.map((e, i) => (
                    <tr className="" key={i}>
                      <td className="whitespace-nowrap px-3 py-4  ">
                        <span className="">{e.name}</span>
                      </td>
                      <td className="whitespace-nowrap px-3 py-4 text-right">
                        {permissions.canUpdate && (
                          <span className="mx-2" onClick={() => Edit(e)}>
                            <i className="fa-solid fa-pen-to-square"></i>
                          </span>
                        )}
                        {permissions.canDelete && (
                          <span className="mx-2" onClick={() => Delete(e.id)}>
                            <i className="fa-solid fa-trash-can"></i>
                          </span>
                        )}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            )}
            {/* {!loading && formValues.length > 0 && (
            <>
              {filtered.map((e, i) => (
                <div className="flex" key={i}>
                  <div>
                    <span className="my-des-alt">{e.name}</span>
                  </div>
                  <div>
                    {permissions.canUpdate && (
                      <span className="mx-2" onClick={() => Edit(e)}>
                        <i className="fa-solid fa-pen-to-square"></i>
                      </span>
                    )}
                    {permissions.canDelete && (
                      <span className="mx-2" onClick={() => Delete(e.id)}>
                        <i className="fa-solid fa-trash-can"></i>
                      </span>
                    )}
                  </div>
                </div>
              ))}
              <ReactPaginate
                breakLabel="..."
                nextLabel="&raquo;"
                onPageChange={handlePage}
                pageRangeDisplayed={5}
                pageCount={pageCount}
                previousLabel="&laquo;"
                renderOnZeroPageCount={null}
                containerClassName="mars-pagination"
                pageClassName="mars-page"
                nextClassName="mars-page"
                previousClassName="mars-page"
                activeClassName="active"
              />
            </>
          )} */}
          </div>
        </div>
      </div>
      {open && (
        <div className="popup_overlay">
          <div className="popup_notify">
            <div className="popup_header">
              <h4>Add City</h4>
              <button className="btn" onClick={openModal}>
                <i className="fa-solid fa-xmark"></i>
              </button>
            </div>
            <div className="popup_body">
              <form onSubmit={formik.handleSubmit}>
                <label htmlFor="name">Name</label>
                <input
                  type="text"
                  id="name"
                  className="input"
                  name="name"
                  placeholder="eg. Karachi"
                  value={formik.values.name}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                />
                <small className="error">
                  {formik.touched.name && formik.errors.name}
                </small>

                <div className="popup_footer">
                  <button
                    className="btn btnclose"
                    onClick={openModal}
                    type="button"
                  >
                    Close
                  </button>
                  <button type="submit" className="btn btntheme">
                    Save
                  </button>
                </div>
              </form>
            </div>
            {/* <div className="popup_footer">
                <button className="btn btnclose"  onClick={openModal}>Close</button>
                <button className="btn btntheme">Save</button>
            </div> */}
          </div>
        </div>
      )}
    </>
  );
};
